import React, { useEffect } from "react";
import {
  BASE_URL,
  handleDynamicRequest,
  useDispatch,
  useNavigate,
} from "../../Common/CommonExport";
import { WhoWeAreImgpowerd } from "../../Common/CommonImages";
import { setLogout } from "../../store/Test/action";
function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLogout());
  }, []);
  return (
    <div>
      <div className="col-lg-12">
        <div className="footer-logo">
          <img src={WhoWeAreImgpowerd} alt="" className="who-wer-are-p-img" />
        </div>
      </div>
    </div>
  );
}

export default Home;
