import React from "react";
import { Successfully } from "../Common/CommonImages";
import { useNavigate } from "react-router-dom";

const TestSubmitMessage = ({ company }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    localStorage.removeItem("persist:root");
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };

  return (
    <div
      className="modal fade alert-model show d-block"
      id="submitted-model"
      tabIndex="-1"
      aria-labelledby="submitted-model"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <div className="modal-body">
            <div className="submittedcontent">
              <img src={Successfully} alt="" className="successfully" />
              <h3>Test Submitted!</h3>
              <p>
                Your test is successfully submitted. Further details and
                procedures will be sent to you via email by {company}. Till then
                be prepared and all the best for future.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSubmitMessage;
