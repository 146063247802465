import { combineReducers } from "redux";
import testReducer from "./Test/reducer";
import screenReducer from "./Screen/reducer";
import cameraReducer from "./Camera/reducer";
import candidateReducer from "./Candidate/reducer";
const rootReducer = combineReducers({
  testReducer,
  cameraReducer,
  screenReducer,
  candidateReducer,
});

export default rootReducer;
