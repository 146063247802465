import axios from "axios";
import base64 from "base64-js";

import {
  SET_IS_CAMERA_REQUIRED,
  SET_WEBCAM_STREAM,
  SET_CAMERA_STATUS,
  SET_CAMERA_ERROR,
} from "./actionType";

import {
  BASE_URL,
  handleDynamicRequestHeader,
} from "../../Common/CommonExport";

import { toast } from "react-toastify";

export const startCamera = () => async (dispatch) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });

    if (stream) {
      dispatch(setCamerError(null));
      dispatch(setWebcamStream(stream));
      dispatch(setCameraStatus(true));
      dispatch(setIsCameraRequired(true));
      stream.oninactive = () => {
        dispatch(setCameraStatus(false));
        dispatch(
          setCamerError({
            name: "AbortError",
            message: "The camera is disconnected",
          })
        );
      };
    }
  } catch (error) {
    dispatch(setCamerError(error));
  }
};

export const setCamerError = (error) => ({
  type: SET_CAMERA_ERROR,
  payload: error,
});

export const stopCamera = () => async (dispatch, getState) => {
  const { webcamStream } = getState().cameraReducer;
  if (webcamStream) {
    webcamStream.getTracks().forEach((track) => track.stop());
    dispatch(setWebcamStream(null));
    dispatch(setCameraStatus(false));
    dispatch(setIsCameraRequired(false));
    dispatch(setCamerError(null));
  }
};

export const setWebcamStream = (stream) => ({
  type: SET_WEBCAM_STREAM,
  payload: stream,
});

export const setCameraStatus = (status) => ({
  type: SET_CAMERA_STATUS,
  payload: status,
});

export const setIsCameraRequired = (isCameraRequired) => ({
  type: SET_IS_CAMERA_REQUIRED,
  payload: isCameraRequired,
});

export const takeSnapshot = (stream, testId, inviteId) => async (dispatch) => {
  const videoTrack = stream.getVideoTracks()[0];
  const imageCapture = new ImageCapture(videoTrack);
  const bitmap = await imageCapture.grabFrame();
  const canvas = document.createElement("canvas");
  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  const context = canvas.getContext("2d");
  context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
  const snapshotData = canvas.toDataURL("image/jpeg");
  dispatch(uploadSnapshot(snapshotData, testId, inviteId));
  console.log("Snapshot taken", snapshotData);
};

export const uploadSnapshot =
  (snapshot, testId, inviteId) => async (dispatch) => {
    const base64Data = snapshot?.replace(
      /^data:image\/(png|jpeg|jpg);base64,/,
      ""
    );
    const imageBytes = base64.toByteArray(base64Data);
    try {
      const method = "GET";
      const url = `${BASE_URL}/api/exam/test/${testId}/S3Url?inviteId=${inviteId}`;
      const wrongResponse = () => {
        toast.error("Error in uploading snapshot image");
      };

      const rightResponse = async (data) => {
        if (data) {
          await axios.request({
            method: "PUT",
            headers: {
              "Content-Type": "image/jpeg",
            },
            url: data.uploadURL,
            data: imageBytes,
          });
          console.log("Image uploaded successfully");
        }
      };

      await handleDynamicRequestHeader(
        method,
        url,
        {},
        null,
        wrongResponse,
        rightResponse
      );
    } catch (error) {
      console.log(error);
    }
  };
