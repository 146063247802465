// cameraReducer.js

import {
  SET_IS_CAMERA_REQUIRED,
  SET_CAMERA_STATUS,
  SET_WEBCAM_STREAM,
  SET_CAMERA_ERROR,
} from "./actionType";

const initialState = {
  isCameraRequired: false,
  isCameraOn: false,
  cameraError: null,
  webcamStream: null,
};

const cameraReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_CAMERA_REQUIRED:
      return {
        ...state,
        isCameraRequired: action.payload,
      };
    case SET_CAMERA_STATUS:
      return {
        ...state,
        isCameraOn: action.payload,
      };
    case SET_CAMERA_ERROR:
      return {
        ...state,
        cameraError: action.payload,
      };
    case SET_WEBCAM_STREAM:
      console.log("action.payload", action.payload);
      return {
        ...state,
        webcamStream: action.payload,
      };
    default:
      return state;
  }
};

export default cameraReducer;
