// eventHandlers.js

export const handleBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = "Are you sure you want to leave this page?";
};

export const handleBackButton = (event) => {
  event.preventDefault();
  console.log("back button clicked");
  if (!window.confirm("Are you sure you want to leave this page?")) {
    window.history.pushState(null, null, window.location.pathname);
  } else {
    // Navigate to the page the user was trying to go to
    window.history.go(1);
  }
};
