import {
  SET_INVITE_ID,
  SET_CANDIDATE_EMAIL,
  SET_CANDIDATE_MODE,
  SET_CANDIDATE_NAME,
  SET_EXTRA_FIELDS,
  SET_CANDIDATE_EXIT,
  SET_CANDIDATE_IMAGE_STATUS,
  SET_CANDIDATE_TAB_SWITCH,
  SET_TOTAL_TIME,
  SET_CANDIDATE_IMAGE,
  SET_CANDIDATE_LOGOUT,
  SET_CANDIDATE_SHUFFLE,
  SET_START_TIME,
} from "./actionTypes";

export const setInviteId = (id) => ({
  type: SET_INVITE_ID,
  payload: id,
});

export const setCandidateEmail = (email) => ({
  type: SET_CANDIDATE_EMAIL,
  payload: email,
});

export const setCandidateName = (name) => ({
  type: SET_CANDIDATE_NAME,
  payload: name,
});
export const setCandidateImage = (image) => ({
  type: SET_CANDIDATE_IMAGE,
  payload: image,
});
export const setCandidateImageStatus = (status) => ({
  type: SET_CANDIDATE_IMAGE_STATUS,
  payload: status,
});

export const setCandidateMode = (mode) => ({
  type: SET_CANDIDATE_MODE,
  payload: mode,
});

export const setExtraFieldArray = (fields) => ({
  type: SET_EXTRA_FIELDS,
  payload: fields,
});

export const setCandidateFullExit = (count) => ({
  type: SET_CANDIDATE_EXIT,
  payload: count,
});

export const setCandidateTabSwitch = (tab) => ({
  type: SET_CANDIDATE_TAB_SWITCH,
  payload: tab,
});

export const setStartTime = (timer) => ({
  type: SET_START_TIME,
  payload: timer,
});

export const setTotalTime = (time) => ({
  type: SET_TOTAL_TIME,
  payload: time,
});

export const setCandidateShuffle = (shuffle) => ({
  type: SET_CANDIDATE_SHUFFLE,
  payload: shuffle,
});

export const setCandidateLogout = (logout) => ({
  type: SET_CANDIDATE_LOGOUT,
  payload: logout,
});
