// screenActions.js
import axios from "axios";
import base64 from "base64-js"; // or whatever library you're using for base64 conversion
import {
  SET_IS_SCREEN_SHARED,
  SET_SCREEN_STREAM,
  SET_IS_SCREEN_SHARING_REQUIRED,
} from "./actionTypes";
import {
  BASE_URL,
  handleDynamicRequestHeader,
} from "../../Common/CommonExport";
import { toast } from "react-toastify";

export const setIsScreenSharingRequired = (isScreenSharingRequired) => ({
  type: SET_IS_SCREEN_SHARING_REQUIRED,
  payload: isScreenSharingRequired,
});

export const setIsScreenShared = (isScreenShared) => ({
  type: SET_IS_SCREEN_SHARED,
  payload: isScreenShared,
});

export const askScreenShare = () => async (dispatch) => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    });
    dispatch({
      type: SET_SCREEN_STREAM,
      payload: stream,
    });
    const videoTracks = stream.getVideoTracks();
    const ScreenOn =
      videoTracks.length > 0 &&
      videoTracks.every((track) => track.readyState === "live");
    dispatch(setIsScreenShared(ScreenOn));
    dispatch(setIsScreenSharingRequired(true));
    stream.oninactive = () => {
      dispatch(setIsScreenShared(false));
    };
    return ScreenOn;
  } catch (error) {
    dispatch(setIsScreenShared(false));
  }
};

export const stopScreenShare = (screenStream) => async (dispatch) => {
  screenStream.getTracks().forEach((track) => track.stop());
  dispatch(setScreenStream(null));
};

export const takeScreenSnapshot =
  (stream, testId, inviteId) => async (dispatch) => {
    console.log(testId, inviteId);
    console.log("takeScreenSnapshot");
    const videoTrack = stream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(videoTrack);
    const bitmap = await imageCapture.grabFrame();
    const canvas = document.createElement("canvas");
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    const context = canvas.getContext("2d");
    context.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
    const snapshotData = canvas.toDataURL("image/jpeg");
    dispatch(uploadSnapshot(snapshotData, testId, inviteId));
    console.log("snapshotData", snapshotData);
  };

export const uploadSnapshot =
  (snapshot, testId, inviteId) => async (dispatch) => {
    console.log("uploadSnapshot");
    const base64Data = snapshot.replace(
      /^data:image\/(png|jpeg|jpg);base64,/,
      ""
    );
    const imageBytes = base64.toByteArray(base64Data);
    try {
      const method = "GET";
      const url = `${BASE_URL}/api/exam/test/${testId}/S3Url?inviteId=${inviteId}&snaphotType=screen`;
      const wrongResponse = () => {
        toast.error("Error in uploading profile image");
      };

      const rightResponse = async (data) => {
        if (data) {
          await axios.request({
            method: "PUT",
            headers: {
              "Content-Type": "image/jpeg",
            },
            url: data.uploadURL,
            data: imageBytes,
          });
          console.log("Image uploaded successfully");
        }
      };

      await handleDynamicRequestHeader(
        method,
        url,
        {},
        null,
        wrongResponse,
        rightResponse
      );
    } catch (error) {
      console.log(error);
    }
  };

export const setScreenStream = (screenStream) => ({
  type: SET_SCREEN_STREAM,
  payload: screenStream,
});
