import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const handleDynamicRequest = async (
  method,
  url,
  requestBody,
  wrongResponse,
  rightResponse
) => {
  try {
    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body:
        method !== "GET" && method !== "HEAD"
          ? JSON.stringify(requestBody)
          : undefined,
    };

    const response = await fetch(url, options);
    const data = await response.json();

    setTimeout(() => {
      if (response.ok) {
        rightResponse(data);
      } else {
        wrongResponse(data);
      }
    }, 100);
  } catch (error) {
    console.error("API Error:", error);
  }
};

// export const handleDynamicRequestHeader = async (method, url, requestBody,token, wrongResponse, rightResponse) => {
//     try {
//          const response = await fetch(url, {
//             method: method,
//             headers: {
//                 'Content-Type': 'application/json',
//                'Authorization':token
//             },
//             body: method !== 'GET' && method !== 'HEAD' ? JSON.stringify(requestBody) : undefined,
//         });
//        const data = await response.json();
//         setTimeout(()=>{
//             if (!response.ok) {
//                 wrongResponse();
//             }
//            else if (response.ok) {
//             if(data){
//                 rightResponse(data);
//             }
//             }
//         });

//     } catch (error) {
//         toast.error('Please check your network connection.');
//         console.error('API Error:', error);
//     }
// };

export const handleDynamicRequestHeader = async (
  method,
  url,
  requestBody,
  token,
  wrongResponse,
  rightResponse
) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body:
        method !== "GET" && method !== "HEAD"
          ? JSON.stringify(requestBody)
          : undefined,
    });

    if (!response.ok) {
      wrongResponse();
      return; // Exit the function
    }
    const data = await response.json();
    if (data) {
      rightResponse(data);
    }
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      toast.error("Please check your network connection.");
    } else {
      toast.error("An unexpected error occurred. Please try again later.");
    }

    console.error("API Error:", error);
  }
};
export const handleDynamicRequestImage = async (
  method,
  url,
  requestBody,
  token,
  wrongResponse,
  rightResponse
) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "image/jpeg",
        Authorization: token,
      },
      body:
        method !== "GET" && method !== "HEAD"
          ? JSON.stringify(requestBody)
          : undefined,
    });
    const data = await response.json();
    setTimeout(() => {
      if (!response.ok) {
        wrongResponse();
      } else if (response.ok) {
        if (data) {
          rightResponse(data);
        }
      }
    });
  } catch (error) {
    console.error("API Error:", error);
  }
};
