// import React from 'react'
import React, { useState, useEffect } from "react";

import {
  CognizantColor,
  testScore,
  clockIcon,
  testSheet,
  userTestImage,
  WhoWeAreImgpowerd,
  IconSkills,
  IconTotal,
  IconAttempted,
  IconPending,
  SubmitIcon,
  AlertTriangle,
} from "../../Common/CommonImages";
import {
  handleBackButton,
  handleBeforeUnload,
} from "../../Common/EventHandlers";

import { BASE_URL, handleDynamicRequest } from "../../Common/CommonExport";
import LinearProgress from "@mui/joy/LinearProgress";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setRemainingTime,
  setLogout,
  setValue,
  setFullScreen,
  setIsSubmitted,
} from "../../store/Test/action";
import Webcam from "react-webcam";
import TestSubmitMessage from "../../Components/TestSubmitMessage";
import {
  setIsScreenSharingRequired,
  setIsScreenShared,
} from "../../store/Screen/actions";
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

function Review() {
  const testName = useSelector((state) => state.testReducer.name);
  const testId = useSelector((state) => state.testReducer.testId);
  const testTime = useSelector((state) => state.testReducer.time);
  const remainingTime = useSelector((state) => state.testReducer.newTime);
  const company = useSelector((state) => state.testReducer.company);
  const totalScore = useSelector((state) => state.testReducer.score);
  const totalQue = useSelector((state) => state.testReducer.questions);
  const selectedAnswers = useSelector(
    (state) => state.testReducer.selectedAnswers
  );
  const isSubmitted = useSelector((state) => state.testReducer.isSubmitted);
  const inviteId = useSelector((state) => state.candidateReducer.id);
  const candidate_full_screen_exit_count = useSelector(
    (state) => state.candidateReducer.count
  );
  const candidate_tab_switch_count = useSelector(
    (state) => state.candidateReducer.tab
  );
  const candidate_total_time_spent = useSelector(
    (state) => state.candidateReducer.time
  );
  const setting = useSelector((state) => state.testReducer.setting);
  const [showWebcam, setShowWebcam] = useState(false);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [stream, setStream] = useState(null);
  const [alertMessage, setAlertMessage] = useState({ success: "", error: "" });
  const [testSummary, setTestSummary] = useState([]);
  // const [testSkills, settestSkills] = useState([]);
  const testSkills = useSelector((state) => state.testReducer.data);
  const dispatch = useDispatch();

  const [isTestSubmitted, setIsTestSubmitted] = useState(false);

  useEffect(() => {
    const snapshotSetting = setting.find(
      (setting) =>
        setting.setting === "Take Snapshots via webcam every 30 second"
    );
    if (snapshotSetting && snapshotSetting.active) {
      startCamera();
    }
  }, [setting]);

  const startCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setStream(stream);
          setShowWebcam(true);
          setCameraStarted(true);
        })
        .catch((error) => {
          console.error("Error starting camera:", error);
        });
    } else {
      console.error("getUserMedia not supported");
    }
  };
  const navigate = useNavigate();

  const handleOverview = () => {
    dispatch(setValue("1"));
    navigate("/overview");
  };

  const handlePrevious = () => {
    navigate("/overview");
  };

  useEffect(() => {
    if (remainingTime <= 1) {
      handleSubmit();
    }
  }, [remainingTime]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs < 10 ? "0" + hrs : hrs}:${mins < 10 ? "0" + mins : mins}:${
      secs < 10 ? "0" + secs : secs
    }`;
  };

  const filteredAnswersLengths = Array.isArray(selectedAnswers)
    ? selectedAnswers.map((answers, index) => {
        if (!answers) {
          return 0;
        }
        return answers.filter((answer) => answer && answer.isAnswered).length;
      })
    : [];

  const answeredPercentage =
    (filteredAnswersLengths.reduce((acc, count) => acc + count, 0) / totalQue) *
    100;

  const handleSubmit = () => {
    const requestBody = {
      inviteId: inviteId,
      candidate_tab_switch_count: candidate_tab_switch_count,
      candidate_total_time_spent: candidate_total_time_spent,
      candidate_full_screen_exit_count: candidate_full_screen_exit_count,
      testEndTime: new Date(),
    };
    const method = "POST";
    const url = `${BASE_URL}/api/exam/test/${testId}/submit`;
    const wrongResponse = () => {
      setAlertMessage({ success: "", error: "Wrong Credentials" });
    };
    const rightResponse = (data) => {
      if (data) {
        setIsTestSubmitted(true);
        dispatch(setFullScreen(false));
        dispatch(setIsScreenSharingRequired(false));
        dispatch(setIsScreenShared(false));
        setLogout(true);
        dispatch(setIsSubmitted(true));
      }
    };
    handleDynamicRequest(
      method,
      url,
      requestBody,
      wrongResponse,
      rightResponse
    );
  };

  return (
    <>
      <div className="App">
        <div className="test-start-header">
          <div className="test-container">
            <div className="row middle-info-item progress-flex">
              <div className="col-xl-8">
                <div className="practice-test-left">
                  <div className="practice-test-name">
                    <img
                      src={testSheet}
                      alt="Test Sheet Icon"
                      className="test-sheet-img"
                    />
                    <h1>{testName}</h1>
                  </div>
                  <span className="cognizant-test">
                    <img src={testScore} alt="" /> Test score:{" "}
                    <div>{totalScore}</div>
                  </span>
                  <span className="cognizant-test no-border-test">
                    <img src={CognizantColor} alt="" /> {company}
                  </span>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="practice-test-right">
                  <span className="clock-icon-text">
                    <img src={clockIcon} alt="" />
                    {formatTime(remainingTime)}
                  </span>
                  <Button className="btn-overview" onClick={handleOverview}>
                    Overview
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="progress-test">
                  <LinearProgress
                    style={{ backgroundColor: "#E8E9EE" }}
                    className="test-line-progress"
                    value={answeredPercentage}
                    thickness={8}
                    determinate
                    size="lg"
                    variant="solid"
                  />
                  <span>{answeredPercentage.toFixed(2)}%</span>
                </div>
              </div>
            </div>
          </div>

          {showWebcam && (
            <div className="user-img-test">
              {!cameraStarted ? (
                <img src={userTestImage} alt="Test Sheet Icon" />
              ) : (
                <Webcam
                  audio={false}
                  className="userimage"
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              )}
            </div>
          )}
        </div>

        <div className="test-tabs">
          <div className="review-main">
            <div className="table-heading">
              <h2>Review your test details before submitting</h2>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr className="head-table">
                      <th scope="col">
                        <div className="th-head">
                          <img src={IconSkills} alt="" /> <span>Skill</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="th-head">
                          <img src={IconTotal} alt="" /> <span>Total</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="th-head">
                          <img src={IconAttempted} alt="" />{" "}
                          <span>Attempted</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="th-head">
                          <img src={IconPending} alt="" /> <span>Pending</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testSkills?.map((test, index) => (
                      <tr key={index}>
                        <th>{test?.skill_id?.skills}</th>
                        <td>
                          {test?.easy_question?.length +
                            test?.medium_question?.length +
                            test?.hard_question?.length}
                        </td>
                        <td>
                          {selectedAnswers[index]
                            ? selectedAnswers[index].filter(
                                (count) => count?.isAnswered
                              ).length
                            : 0}
                        </td>
                        <td>
                          {test?.easy_question?.length +
                            test?.medium_question?.length +
                            test?.hard_question?.length -
                            (selectedAnswers[index]
                              ? selectedAnswers[index].filter(
                                  (count) => count?.isAnswered
                                ).length
                              : 0)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="review-btn">
                <Button className="prev-btn" onClick={handlePrevious}>
                  Previous
                </Button>
                <Button
                  className="save-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#alert-model"
                >
                  Submit Test{" "}
                </Button>
              </div>
              <div className="review-footer-logo">
                <img src={WhoWeAreImgpowerd} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Model Review Question */}
      <div
        className="modal fade alert-model"
        id="alert-model"
        tabIndex="-1"
        aria-labelledby="alert-model"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <div className="alertHeader">
                <img src={SubmitIcon} alt="" className="camera-icon" />
                <h3>Submit Test</h3>
              </div>
              <div className="alert-trangle-text">
                <img src={AlertTriangle} alt="" /> Once the test is submitted
                you cannot make changes.
              </div>
            </div>
            <div className="modal-footer review-footer">
              <Button
                type="button"
                className="cancelModel"
                data-bs-dismiss="modal"
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="submit-review"
                data-bs-dismiss="modal"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Model Test Submitted */}
      {isTestSubmitted && <TestSubmitMessage company={company} />}
    </>
  );
}

export default Review;
