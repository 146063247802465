import {
  SET_IS_SCREEN_SHARING_REQUIRED,
  SET_IS_SCREEN_SHARED,
  SET_SCREEN_STREAM,
} from "./actionTypes";

const initialState = {
  isScreenSharingRequired: false,
  isScreenShared: false,
  screenStream: null,
};

const screenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SCREEN_SHARING_REQUIRED:
      return {
        ...state,
        isScreenSharingRequired: action.payload,
      };
    case SET_IS_SCREEN_SHARED:
      return {
        ...state,
        isScreenShared: action.payload,
      };
    case SET_SCREEN_STREAM:
      return {
        ...state,
        screenStream: action.payload,
      };

    default:
      return state;
  }
};

export default screenReducer;
