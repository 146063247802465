import React, { useState, useEffect, useRef } from "react";
import {
  logo,
  testSheet,
  WhoWeAreImgpowerd,
  Calender,
  grayClock,
  grayIcon,
  userImage,
  dummyuserImage,
  greenCheck,
  CameraIcon,
  uploadProfile,
  uploadIcon,
  statTestIcon,
  AlertTriangle,
} from "../../Common/CommonImages";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { setFullScreen, setTestData } from "../../store/Test/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import base64 from "base64-js";
import {
  setCandidateMode,
  setCandidateImage,
  setInviteId,
  setCandidateImageStatus,
  setStartTime,
} from "../../store/Candidate/action";

import { askScreenShare, stopScreenShare } from "../../store/Screen/actions";

import { startCamera, stopCamera } from "../../store/Camera/action";

import {
  BASE_URL,
  handleDynamicRequest,
  handleDynamicRequestHeader,
  handleDynamicRequestImage,
} from "../../Common/CommonExport";
import Webcam from "react-webcam";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

function StartTest() {
  const testName = useSelector((state) => state.testReducer.name);
  const testTime = useSelector((state) => state.testReducer.time);
  const testDate = useSelector((state) => state.testReducer.date);
  const setting = useSelector((state) => state.testReducer.setting);
  const testId = useSelector((state) => state.testReducer.testId);
  const [loading, setLoading] = useState(false);

  const testInstructions = useSelector(
    (state) => state.testReducer.instructions
  );
  const cameraStarted = useSelector((state) => state.cameraReducer.isCameraOn);

  const testSummary = useSelector((state) => state.testReducer.testSummary);

  // State to track if the camera has started
  const webcamStream = useSelector((state) => state.cameraReducer.webcamStream);
  const cameraError = useSelector((state) => state.cameraReducer.cameraError);

  const [imageSrc, setImageSrc] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [timer, setTimer] = useState(3);
  const [ShowTimer, setShowTimer] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const candidateName = useSelector((state) => state.candidateReducer.name);
  const candidateEmail = useSelector((state) => state.candidateReducer.email);
  const candidateFields = useSelector((state) => state.candidateReducer.fields);
  const candidateImage = useSelector((state) => state.candidateReducer.image);
  const candidateImageStatus = useSelector(
    (state) => state.candidateReducer.status
  );
  const screenStream = useSelector((state) => state.screenReducer.screenStream);
  const isScreenShared = useSelector(
    (state) => state.screenReducer.isScreenShared
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  const handleStartCamera = () => {
    dispatch(startCamera());
  };

  const handleStopCamera = () => {
    dispatch(stopCamera());
  };

  useEffect(() => {
    const handleBackButton = () => {
      if (webcamStream) {
        webcamStream.getTracks().forEach((track) => track.stop());
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // Listen for popstate event
    window.addEventListener("popstate", handleBackButton);
    // Listen for beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("popstate", handleBackButton);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [webcamStream]);

  const capture = () => {
    if (webcamStream.current) {
      const newImageSrc = webcamStream.current.getScreenshot();
      setImageSrc(newImageSrc);
      dispatch(setCandidateImage(newImageSrc));
    }
  };

  const INITIAL_TIMER_VALUE = 3;

  const takePicture = () => {
    setShowTimer(true);
    setShowWebcam(true);
    setImageSrc(null);
    dispatch(setCandidateImage(null));

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          capture();
          setShowTimer(false);
          return INITIAL_TIMER_VALUE;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  };

  const savePicture = () => {
    // Save logic here
    setShowWebcam(false);
  };

  const snapshotSetting = setting.find(
    (setting) => setting.setting === "Take Snapshots via webcam every 30 second"
  );
  const screenSetting = setting.find(
    (setting) => setting.setting === "Take screen snapshots on tab switch"
  );

  const navigate = useNavigate();
  const handleEnterDetails = () => {
    navigate("/enter-details");
  };

  const handleOverview = async () => {
    try {
      if (
        (screenSetting && screenSetting.active && !isScreenShared) ||
        (snapshotSetting && snapshotSetting.active && !cameraStarted) ||
        (snapshotSetting && snapshotSetting.active && !imageSrc) ||
        !privacyChecked
      ) {
        toggleModal();
      } else {
        getCombinedQuestions(testId);
        dispatch(setCandidateMode(true));
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  const handleProfileSnapshot = async (inviteId) => {
    try {
      if (candidateImage && !candidateImageStatus) {
        const snapshotData = candidateImage;
        const requestBody = { snapshotData };
        const method = "GET";
        const url = `${BASE_URL}/api/exam/test/${testId}/S3Url?inviteId=${inviteId}&snaphotType=profile`;

        const wrongResponse = () => {
          toast.error("Error in uploading profile image");
        };

        const rightResponse = async (data) => {
          if (data) {
            const base64Data = snapshotData.replace(
              /^data:image\/(png|jpeg|jpg);base64,/,
              ""
            );
            const imageBytes = base64.toByteArray(base64Data);

            await axios.request({
              method: "PUT",
              headers: {
                "Content-Type": "image/jpeg",
              },
              url: data.uploadURL,
              data: imageBytes,
            });
            dispatch(setCandidateImageStatus(true));
          }
        };

        await handleDynamicRequestHeader(
          method,
          url,
          requestBody,
          null,
          wrongResponse,
          rightResponse
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCandidate = async (uniqueId, questionsList) => {
    const requestBody = {
      candidateName: candidateName,
      candidateEmail: candidateEmail,
      extraFieldArray: [],
      testStartTime: new Date(),
      inviteTime: new Date(),
      candidateQuestions: questionsList,
    };
    const method = "POST";
    const url = `${BASE_URL}/api/exam/test/${uniqueId}/candidateFields`;

    const wrongResponse = () => {
      toast.error("Error in creating candidate");
      setLoading(false);
    };
    const rightResponse = (data) => {
      if (data) {
        setLoading(false);
        dispatch(setInviteId(data.data._id));
        handleProfileSnapshot(data.data._id);
        dispatch(setStartTime(Date.now()));
        navigate("/overview", { replace: true });
      }
    };
    handleDynamicRequestHeader(
      method,
      url,
      requestBody,
      null,
      wrongResponse,
      rightResponse
    );
  };

  const getCombinedQuestions = (uniqueId) => {
    setLoading(true);
    const questionsList = [];
    const randomSkills = [];
    const requestBody = {};
    const method = "GET";
    const url = `${BASE_URL}/api/exam/test/${uniqueId}/Combinequestions`;
    const wrongResponse = () => {
      toast.error("Error in getting questions");
      setLoading(false);
    };
    const rightResponse = (data) => {
      if (data) {
        const fullScreenSetting = setting.find(
          (setting) => setting.setting === "Turn on fullscreen while test"
        );

        if (fullScreenSetting && fullScreenSetting.active) {
          dispatch(setFullScreen(true));
        }
        // setTestSummary(data);
        const candidateShuffleSetting = setting.find(
          (setting) =>
            setting.setting === "Shuffle Questions for each candidate"
        );

        data?.testSummaryData?.skills.forEach((skill) => {
          if (!skill.isRandom) {
            const tempQuestions = [
              ...skill.easy_question,
              ...skill.hard_question,
              ...skill.medium_question,
            ];
            questionsList.push(...tempQuestions);
          } else {
            const scores = {};
            skill.score[0].split(",").forEach((item) => {
              const [key, value] = item.split("(");
              scores[key.toLowerCase()] = parseInt(value);
            });
            randomSkills.push({
              skillId: skill.skill_id,
              scores: scores,
            });
            skill.easy_question = [];
            skill.medium_question = [];
            skill.hard_question = [];
          }
        });

        data?.random.forEach((skill) => {
          const tempQuestions = skill.question.map((q) => ({
            ...q,
            score:
              randomSkills.find(
                (rSkill) => rSkill.skillId._id === skill.skillId._id
              )?.scores[q.difficultyLevelId.level] || 0,
            isRandom: true,
          }));

          tempQuestions.forEach((question) => {
            if (question.difficultyLevelId.level === "easy") {
              data.testSummaryData.skills
                .find((sk) => sk.skill_id._id === skill.skillId._id)
                .easy_question.push(question);
            } else if (question.difficultyLevelId.level === "medium") {
              data.testSummaryData.skills
                .find((sk) => sk.skill_id._id === skill.skillId._id)
                .medium_question.push(question);
            } else if (question.difficultyLevelId.level === "hard") {
              data.testSummaryData.skills
                .find((sk) => sk.skill_id._id === skill.skillId._id)
                .hard_question.push(question);
            }
          });

          questionsList.push(...tempQuestions);
        });

        if (candidateShuffleSetting.active === true) {
          data.testSummaryData.skills = data.testSummaryData.skills.map(
            (skill) => {
              return {
                ...skill,
                easy_question: shuffle([...skill.easy_question]),
                medium_question: shuffle([...skill.medium_question]),
                hard_question: shuffle([...skill.hard_question]),
              };
            }
          );

          function shuffle(array) {
            for (let i = array.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
          }

          dispatch(setTestData(data?.testSummaryData?.skills));
          for (let i = questionsList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [questionsList[i], questionsList[j]] = [
              questionsList[j],
              questionsList[i],
            ];
          }
        } else {
          dispatch(setTestData(data?.testSummaryData?.skills));
        }

        // setTestQuestions(questionsList);
        handleCandidate(uniqueId, questionsList); // Call handleCandidate with questionsList
        return questionsList;
      }
    };
    handleDynamicRequest(
      method,
      url,
      requestBody,
      wrongResponse,
      rightResponse
    );
  };

  const grayClockText = {
    color: "#535A5F",
  };

  const checkboxStyle = {
    color: "#FF6812",
    width: "50px",
    height: "50px",
  };

  const checkboxStartTest = {
    color: "#FF6812",
    width: "32px",
    height: "32px",
  };

  const startScreenShare = async () => {
    dispatch(askScreenShare());
  };

  const handleStopScreenShare = () => {
    if (screenStream) {
      dispatch(stopScreenShare(screenStream));
    }
  };

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handlePrivacyCheck = (event) => {
    const isChecked = event.target.checked;
    setPrivacyChecked(isChecked);
  };

  return (
    <>
      <div className="App">
        <ToastContainer />
        <div className="main-head">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <header className="logo-header">
                  <a href="/">
                    <img src={logo} alt="logo" className="hire-logo" />
                  </a>
                </header>
              </div>
            </div>
          </div>
        </div>

        <div className="progress-steps">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="progress-steps-inner">
                  <a href="/">
                    <img src={logo} alt="logo" className="desktop-logo" />
                  </a>
                  <ul className="timeline">
                    <li className="active completed-check">
                      <span>Summary</span>
                    </li>
                    <li className="active completed-check">
                      <span>Enter Details</span>
                    </li>
                    <li className="active ">
                      <span>Start Test</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="middle-steps-content">
          <div className="max-container-middle-text">
            <div className="row">
              <div className="col-lg-12">
                <div className="middle-steps-inner-content">
                  <div className="row middle-info-item">
                    <div className="col-lg-12">
                      <div className="practice-test-left">
                        <div className="practice-test-name">
                          <img
                            src={testSheet}
                            alt="Test Sheet Icon"
                            className="test-sheet-img"
                          />
                          <h1>{testName}</h1>
                        </div>
                        <span className="claender">
                          <img src={Calender} alt="" /> {testDate.startDate} -{" "}
                          {testDate.endDate}
                        </span>
                        <p style={grayClockText}>
                          <img src={grayClock} alt="" />
                          {testTime} hrs
                        </p>
                      </div>
                    </div>
                  </div>
                  {snapshotSetting && snapshotSetting.active && (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="test-description">
                          <div className="user-test-required">
                            <div className="user-test-info">
                              {!cameraStarted ? (
                                <img
                                  src={userImage}
                                  alt="Test Sheet Icon"
                                  className="userimage"
                                />
                              ) : (
                                <Webcam
                                  audio={false}
                                  className="userimage"
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={videoConstraints}
                                  ref={webcamStream}
                                />
                              )}

                              <div>
                                <h2>Why Camera?</h2>
                                {!cameraStarted ? (
                                  <img
                                    src={grayIcon}
                                    alt="Test Sheet Icon"
                                    className="greencheck"
                                  />
                                ) : (
                                  <img
                                    src={greenCheck}
                                    alt="Test Sheet Icon"
                                    className="greencheck"
                                  />
                                )}
                                <p>
                                  This test requires webcam access for
                                  proctoring snapshots will be taken in every
                                  30secs.
                                </p>
                                {!cameraStarted ? (
                                  <Button
                                    className="startcamera"
                                    startIcon={<VideocamIcon />}
                                    onClick={handleStartCamera}
                                  >
                                    Start Camera
                                  </Button>
                                ) : (
                                  <Button
                                    className="stopcamera"
                                    onClick={handleStopCamera}
                                    startIcon={<VideocamOffIcon />}
                                  >
                                    Stop Camera
                                  </Button>
                                )}
                              </div>
                            </div>

                            <div className="user-test-info">
                              {!imageSrc ? (
                                <img
                                  src={dummyuserImage}
                                  alt="Captured Image"
                                  className="userimage"
                                />
                              ) : (
                                <img
                                  src={imageSrc}
                                  alt="Captured Image"
                                  className="userimage"
                                />
                              )}
                              <div>
                                <h2>Profile Picture</h2>
                                {!imageSrc ? (
                                  <img
                                    src={grayIcon}
                                    alt="Test Sheet Icon"
                                    className="greencheck"
                                  />
                                ) : (
                                  <img
                                    src={greenCheck}
                                    alt="Test Sheet Icon"
                                    className="greencheck"
                                  />
                                )}
                                <p>
                                  Make sure your picture is neat and clean
                                  because the picture is going to be used
                                  throughout the recruitment process.
                                </p>
                                <Button
                                  className="startcamera"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cameraModal"
                                  startIcon={<PanoramaIcon />}
                                >
                                  {imageSrc ? "Retake Picture" : "Take Picture"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {screenSetting && screenSetting.active && (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="test-description">
                          <h2>Present Screen</h2>
                          {!isScreenShared ? (
                            <img
                              src={grayIcon}
                              alt="Test Sheet Icon"
                              className="greencheck"
                            />
                          ) : (
                            <img
                              src={greenCheck}
                              alt="Test Sheet Icon"
                              className="greencheck"
                            />
                          )}

                          <p>
                            This test requires your entire screen to be shared
                            for proctoring. If you share single tab or window
                            you will be marked as absent and get eliminated from
                            recruitment process.
                          </p>
                          {!isScreenShared ? (
                            <Button
                              startIcon={<PresentToAllIcon />}
                              className="startcamera"
                              onClick={startScreenShare}
                            >
                              Start Screen Share
                            </Button>
                          ) : (
                            <Button
                              startIcon={<CancelPresentationIcon />}
                              className="stopcamera"
                              onClick={handleStopScreenShare}
                            >
                              Stop Screen Share
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="test-description">
                        <h2>Test Instruction</h2>
                        <ul>
                          {testInstructions?.map((instruction, index) => {
                            return (
                              <li key={index}>{instruction.instruction}</li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="start-test-page">
                  <FormControl className="privacy-policy-check">
                    <Checkbox
                      onChange={handlePrivacyCheck}
                      checked={privacyChecked}
                    />
                    <p className="check-text">
                      I agree to the privacy policy and terms of Use of Hire360
                    </p>
                  </FormControl>
                  <div className="start-test-flex">
                    <Button onClick={handleEnterDetails} className="back">
                      Back
                    </Button>
                    <Button
                      data-bs-toggle="modal"
                      data-bs-target="#startTestModel"
                      disabled={!privacyChecked}
                      className="start-test-btn"
                    >
                      Start Test
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="footer-logo">
                  <img
                    src={WhoWeAreImgpowerd}
                    alt=""
                    className="who-wer-are-p-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* cameraModal One */}

        <div
          className="modal fade help-model"
          id="cameraModal"
          tabIndex="-1"
          aria-labelledby="cameraModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

              <div className="modal-body camera-model-body">
                <div className="cmera-header-flex">
                  <img src={CameraIcon} alt="" className="camera-icon" />
                  <div>
                    <h3>Upload profile picture</h3>
                    <p>
                      Please provide a clear image of your face against a plain
                      background for the selection process.
                    </p>
                  </div>
                </div>
                <div>
                  <img src={uploadProfile} alt="" className="uploadProfile" />
                </div>
              </div>

              <div className="modal-footer">
                <Button
                  type="button"
                  className="cancelModel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={() => setShowWebcam(true)}
                  data-bs-toggle="modal"
                  data-bs-target="#cameraModalTwo"
                >
                  {" "}
                  <img src={uploadIcon} alt="" className="uploadIcon" /> Take a
                  Picture
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* cameraModal Two */}

        <div
          className="modal fade help-model"
          id="cameraModalTwo"
          tabIndex="-1"
          aria-labelledby="cameraModalTwo"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

              <div className="modal-body camera-model-body">
                <div className="cmera-header-flex">
                  <img src={CameraIcon} alt="" className="camera-icon" />
                  <div>
                    <h3>Upload profile picture</h3>
                    <p>
                      Please provide a clear image of your face against a plain
                      background for the selection process.
                    </p>
                  </div>
                </div>
                <div className="uploade-fifty50">
                  <div>
                    {showWebcam && (
                      <div>
                        <Webcam
                          audio={false}
                          className="uploadProfile"
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                          ref={webcamStream}
                        />
                        {timer > 0 && ShowTimer && (
                          <div className="timerContainer">
                            <div className="timerText">{timer}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {imageSrc ? (
                      <img
                        src={imageSrc}
                        alt="Snapshot"
                        className="uploadProfile"
                      />
                    ) : (
                      <>
                        <img
                          src={uploadProfile}
                          alt="Snapshot"
                          className="uploadProfile"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="cancelModel"
                  onClick={takePicture}
                >
                  {imageSrc ? "Retake Picture" : "Take Picture"}
                </button>

                <button
                  type="button"
                  onClick={savePicture}
                  data-bs-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Start Test Model */}

        <div
          className="modal fade help-model"
          id="startTestModel"
          tabIndex="-1"
          aria-labelledby="startTestModel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

              <div className="modal-body camera-model-body">
                <div className="cmera-header-flex">
                  <img src={statTestIcon} alt="" className="camera-icon" />
                  <div>
                    <h3>Start Test</h3>
                    <p>Please check the below prerequisites. </p>
                  </div>
                </div>
                <Box className="starttest-main">
                  {screenSetting && screenSetting.active && (
                    <React.Fragment>
                      <FormControl className="privacy-policy-check">
                        <Checkbox
                          style={checkboxStartTest}
                          checked={isScreenShared}
                        />
                        <p className="check-text">Start Screen Share</p>
                      </FormControl>
                    </React.Fragment>
                  )}
                  {snapshotSetting && snapshotSetting.active && (
                    <React.Fragment>
                      <FormControl className="privacy-policy-check">
                        <Checkbox
                          style={checkboxStartTest}
                          checked={cameraStarted}
                        />
                        <p className="check-text">
                          Why Camera & Profile Picture
                        </p>
                      </FormControl>
                    </React.Fragment>
                  )}
                  {snapshotSetting && snapshotSetting.active && (
                    <React.Fragment>
                      <FormControl className="privacy-policy-check">
                        <Checkbox style={checkboxStartTest} checked />
                        <p className="check-text">Multi Screen Check</p>
                      </FormControl>
                    </React.Fragment>
                  )}

                  <FormControl className="privacy-policy-check">
                    <Checkbox
                      style={checkboxStartTest}
                      onChange={handlePrivacyCheck}
                      checked={privacyChecked}
                    />
                    <p className="check-text">I Agree Terms & Conditions</p>
                  </FormControl>
                </Box>
              </div>

              <div className="modal-footer">
                <Button
                  type="button"
                  className="cancelModel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Button>
                {
                  <Button
                    type="button"
                    onClick={handleOverview}
                    data-bs-dismiss="modal"
                    disabled={loading}
                  >
                    {loading && (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ marginRight: "5px" }}
                      />
                    )}{" "}
                    Start
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>

        {/* Alert Model */}
        <Modal isOpen={showModal} toggle={toggleModal} className="alert-model">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggleModal}
            ></button>
            <ModalBody>
              <div className="alert-trangle-text">
                {!privacyChecked ? (
                  <React.Fragment>
                    <img src={AlertTriangle} alt="" />
                    Please agree to the privacy policy to start the test
                  </React.Fragment>
                ) : screenSetting && screenSetting.active && !isScreenShared ? (
                  <React.Fragment>
                    <img src={AlertTriangle} alt="" />
                    Please Share your Screen to start the test
                  </React.Fragment>
                ) : snapshotSetting &&
                  snapshotSetting.active &&
                  !cameraStarted ? (
                  <React.Fragment>
                    <img src={AlertTriangle} alt="" />
                    Turn on your camera to start the test
                  </React.Fragment>
                ) : snapshotSetting && snapshotSetting.active && !imageSrc ? (
                  <React.Fragment>
                    <img src={AlertTriangle} alt="" />
                    Please upload your photo
                  </React.Fragment>
                ) : null}
              </div>
            </ModalBody>
            <ModalFooter className="review-footer">
              <Button
                color="secondary"
                onClick={toggleModal}
                className="cancelModel"
              >
                close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default StartTest;
