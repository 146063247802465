import {
  SET_INVITE_ID,
  SET_CANDIDATE_EMAIL,
  SET_CANDIDATE_IMAGE_STATUS,
  SET_CANDIDATE_IMAGE,
  SET_CANDIDATE_MODE,
  SET_CANDIDATE_NAME,
  SET_EXTRA_FIELDS,
  SET_CANDIDATE_EXIT,
  SET_CANDIDATE_TAB_SWITCH,
  SET_TOTAL_TIME,
  SET_CANDIDATE_SHUFFLE,
  SET_CANDIDATE_LOGOUT,
  SET_START_TIME,
} from "./actionTypes";

const initialState = {
  id: null,
  email: null,
  name: null,
  image: null,
  status: null,
  mode: null,
  fields: null,
  count: null,
  tab: null,
  time: null,
  shuffle: null,
  logout: null,
  startTime: null,
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVITE_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_CANDIDATE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_CANDIDATE_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_CANDIDATE_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case SET_CANDIDATE_IMAGE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_CANDIDATE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case SET_EXTRA_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };
    case SET_CANDIDATE_EXIT:
      return {
        ...state,
        count: action.payload,
      };
    case SET_CANDIDATE_TAB_SWITCH:
      console.log("tab", action.payload);
      return {
        ...state,
        tab: action.payload,
      };

    case SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case SET_TOTAL_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case SET_CANDIDATE_SHUFFLE:
      return {
        ...state,
        shuffle: action.payload,
      };
    case SET_CANDIDATE_LOGOUT:
      return {
        ...initialState,
        logout: null,
      };

    default:
      return state;
  }
};

export default candidateReducer;
