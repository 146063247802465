import {
  SET_TEST_ID,
  SET_TEST_NAME,
  SET_QUESTION_INDEX,
  SET_CANDIDATE_SETTING,
  SET_TEST_DATE,
  SET_VALUE,
  SET_TEST_TIME,
  SET_REMAINING_TIME,
  SET_TEST_INSTRUCTIONS,
  SET_TEST_SETTINGS,
  SET_LINK,
  SET_COMPANY,
  SET_TOTAL_SCORE,
  SET_TOTAL_QUESTIONS,
  SET_ANSWER,
  RESET_ANSWER,
  SET_FULL_SCREEN,
  SET_TEST_DATA,
  LOGOUT,
  SET_IS_SUBMITTED,
} from "./actionTypes";

export const setTestId = (testId) => ({
  type: SET_TEST_ID,
  payload: testId,
});

export const setTestName = (name) => ({
  type: SET_TEST_NAME,
  payload: name,
});

export const setTestLink = (link) => ({
  type: SET_LINK,
  payload: link,
});

export const setValue = (value) => ({
  type: SET_VALUE,
  payload: value,
});

export const setQuestionIndex = (index) => ({
  type: SET_QUESTION_INDEX,
  payload: index,
});

export const setTestInstructions = (instructions) => ({
  type: SET_TEST_INSTRUCTIONS,
  payload: instructions,
});

export const setCandiateSettings = (candidate) => ({
  type: SET_CANDIDATE_SETTING,
  payload: candidate,
});

export const setTestDate = (date) => ({
  type: SET_TEST_DATE,
  payload: date,
});

export const setTestTime = (time) => ({
  type: SET_TEST_TIME,
  payload: time,
});

export const setRemainingTime = (newTime) => ({
  type: SET_REMAINING_TIME,
  payload: newTime,
});

export const setFullScreen = (screen) => ({
  type: SET_FULL_SCREEN,
  payload: screen,
});

export const setTestSettings = (settings) => ({
  type: SET_TEST_SETTINGS,
  payload: settings,
});

export const setCompany = (company) => ({
  type: SET_COMPANY,
  payload: company,
});

export const setTotalScore = (score) => ({
  type: SET_TOTAL_SCORE,
  payload: score,
});

export const setTotalQuestions = (questions) => ({
  type: SET_TOTAL_QUESTIONS,
  payload: questions,
});

export const setAnswer = (
  testDataIndex,
  questionIndex,
  answerIndex,
  isAnswered,
  isReviewed,
  timeSpent
) => ({
  type: SET_ANSWER,
  payload: {
    testDataIndex,
    questionIndex,
    answerIndex,
    isReviewed,
    isAnswered,
    timeSpent,
  },
});

export const resetAnswer = (testDataIndex, questionIndex) => ({
  type: RESET_ANSWER,
  payload: { testDataIndex, questionIndex },
});

export const setTestData = (data) => ({
  type: SET_TEST_DATA,
  payload: data,
});

export const setLogout = (logout) => ({
  type: LOGOUT,
  payload: logout,
});

export const setIsSubmitted = (isSubmitted) => ({
  type: SET_IS_SUBMITTED,
  payload: isSubmitted,
});
