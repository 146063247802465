import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  publicRoutes,
  authProtectedRoutes,
  authProtectedRoutesFull,
} from "./routes/index";
import { useSelector, useDispatch } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "suneditor/dist/css/suneditor.min.css";

import "./App.css";
import { AlertTriangle } from "./Common/CommonImages";

import { askScreenShare, takeScreenSnapshot } from "./store/Screen/actions";
import { setTotalTime } from "./store/Candidate/action";
import { startCamera, takeSnapshot } from "./store/Camera/action";
import { handleBackButton, handleBeforeUnload } from "./Common/EventHandlers";
import { setRemainingTime } from "./store/Test/action";

function App() {
  const dispatch = useDispatch();
  const testId = useSelector((state) => state.testReducer?.testId);
  const inviteId = useSelector((state) => state.candidateReducer?.id);
  const startTime = useSelector((state) => state.candidateReducer?.startTime);
  const isSubmitted = useSelector((state) => state.testReducer?.isSubmitted);
  const screen = useSelector((state) => state.testReducer?.screen);
  const testTime = useSelector((state) => state.testReducer.time);
  const mode = useSelector((state) => state.candidateReducer?.mode);
  const settings = useSelector((state) => state.testReducer?.setting);
  const checkCameraErrorIntervalId = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [action, setAction] = useState(null);

  //Camera states
  const isCameraRequired = useSelector(
    (state) => state.cameraReducer?.isCameraRequired
  );

  const webcamStream = useSelector(
    (state) => state.cameraReducer?.webcamStream
  );
  const cameraError = useSelector((state) => state.cameraReducer?.cameraError);

  const isCameraOn = useSelector((state) => state.cameraReducer?.isCameraOn);

  //Screen sharing states
  const isScreenSharingRequired = useSelector(
    (state) => state.screenReducer?.isScreenSharingRequired
  );
  const isScreenShared = useSelector(
    (state) => state.screenReducer?.isScreenShared
  );
  const screenStream = useSelector(
    (state) => state.screenReducer?.screenStream
  );

  const enterFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error("Error attempting to enter fullscreen mode:", err);
        });
    } else if (element.mozRequestFullScreen) {
      element
        .mozRequestFullScreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error("Error attempting to enter fullscreen mode:", err);
        });
    } else if (element.webkitRequestFullscreen) {
      element
        .webkitRequestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error("Error attempting to enter fullscreen mode:", err);
        });
    } else if (element.msRequestFullscreen) {
      element
        .msRequestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error("Error attempting to enter fullscreen mode:", err);
        });
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Error attempting to exit fullscreen mode:", err);
        });
    } else if (document.mozCancelFullScreen) {
      document
        .mozCancelFullScreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Error attempting to exit fullscreen mode:", err);
        });
    } else if (document.webkitExitFullscreen) {
      document
        .webkitExitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Error attempting to exit fullscreen mode:", err);
        });
    } else if (document.msExitFullscreen) {
      document
        .msExitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error("Error attempting to exit fullscreen mode:", err);
        });
    }
  };

  useEffect(() => {
    // clear the site data
    window.localStorage.clear();
    window.sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (startTime !== null) {
      const timer = setInterval(() => {
        const totalTime = Math.floor((Date.now() - startTime) / 1000);
        dispatch(setTotalTime(totalTime));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [startTime]);

  useEffect(() => {
    if (startTime !== null) {
      let intervalId;
      let remainingTime = null;

      console.log(isSubmitted, "isSubmitted");

      const convertToSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
          console.error("Invalid time string:", timeString); // Add this line
          return 0;
        }
        return hours * 3600 + minutes * 60 + seconds;
      };

      const totalSeconds = convertToSeconds(testTime);
      const endTime = startTime + totalSeconds * 1000;

      intervalId = setInterval(() => {
        if (isSubmitted) {
          clearInterval(intervalId);
          dispatch(setRemainingTime(0));
          return;
        }
        const remainingSeconds = Math.round((endTime - Date.now()) / 1000);

        if (remainingTime !== remainingSeconds) {
          remainingTime = remainingSeconds;
          dispatch(
            setRemainingTime(remainingSeconds > 0 ? remainingSeconds : 0)
          );
        }

        if (remainingSeconds === 600) {
          document.body.style.animation = "shake 0.5s";
          document.body.style.animationIterationCount = "infinite";
          document.getElementById("message").classList.remove("hidden");
          document.getElementById("message").classList.add("show");
          setTimeout(() => {
            document.body.style.animation = "";
            document.getElementById("message").classList.remove("show");
            document.getElementById("message").classList.add("hidden");
          }, 2000);
        }

        if (remainingSeconds <= 5) {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.removeEventListener("popstate", handleBackButton);
        }

        if (Date.now() > endTime - 1000) {
          setRemainingTime(0);
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [startTime, isSubmitted, testTime]);

  useEffect(() => {
    if (screen && isFullscreen) {
      setShowModal(false);
      enterFullScreen();
    } else if (screen) {
      setShowModal(true);
      setAlertMessage("Do not exit the full screen.");
      setAction({
        action: "enterFullScreen",
        actionText: "Enter Full Screen",
      });
      enterFullScreen();
    }
  }, [screen, isFullscreen]);

  useEffect(() => {
    if (mode && !isFullscreen) {
      const handleFullScreenChange = () => {
        setIsFullscreen(document.fullscreenElement !== null);
      };

      document.addEventListener("fullscreenchange", handleFullScreenChange);
      document.addEventListener("mozfullscreenchange", handleFullScreenChange);
      document.addEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.addEventListener("msfullscreenchange", handleFullScreenChange);
      setIsFullscreen(false);
      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "mozfullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "webkitfullscreenchange",
          handleFullScreenChange
        );
        document.removeEventListener(
          "msfullscreenchange",
          handleFullScreenChange
        );
        setIsFullscreen(false);
      };
    }
  }, [mode]);

  useEffect(() => {
    let intervalId;
    if (isCameraRequired && isCameraOn && webcamStream && inviteId) {
      intervalId = setInterval(() => {
        dispatch(takeSnapshot(webcamStream, testId, inviteId));
      }, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isCameraRequired, isCameraOn, inviteId, webcamStream]);

  const checkCameraError = () => {
    console.log(cameraError, "cameraError");
    if (cameraError !== null) {
      if (cameraError.name === "NotAllowedError") {
        setAlertMessage(
          <span>
            Camera access is disabled by the user. Please enable the camera to
            continue the test. For more information{" "}
            <a
              href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
              target="blank"
            >
              click here
            </a>
            .
          </span>
        );
        setAction({
          action: "startCamera",
          actionText: "Start Camera",
        });
        setShowModal(true);
        console.log("NotAllowedError");
      }
      if (cameraError.name === "NotFoundError") {
        setAlertMessage(
          "No camera found. Please connect a camera to continue the test."
        );
        setAction({
          action: "startCamera",
          actionText: "Start Camera",
        });
        setShowModal(true);
      }
      if (cameraError.name === "NotReadableError") {
        setAlertMessage(
          "The camera is being used by another application. Please close the application to continue the test."
        );
        setAction({
          action: "startCamera",
          actionText: "Start Camera",
        });
        setShowModal(true);
      }
      if (cameraError.name === "OverconstrainedError") {
        setAlertMessage(
          "The camera is not supported. Please connect a supported camera to continue the test."
        );
        setAction({
          action: "startCamera",
          actionText: "Start Camera",
        });
        setShowModal(true);
      }
      if (cameraError.name === "SecurityError") {
        setAlertMessage(
          "The camera is disabled by the browser. Please enable the camera to continue the test."
        );
        setAction({
          action: "startCamera",
          actionText: "Start Camera",
        });
        setShowModal(true);
      }

      if (cameraError.name === "AbortError" && isCameraRequired) {
        setAlertMessage(
          "The camera is disconnected. Please connect the camera to continue the test."
        );
        setAction({
          action: "startCamera",
          actionText: "Start Camera",
        });
        setShowModal(true);
      }
    }

    if (cameraError === null) {
      console.log("Camera started");
      setShowModal(false);
      stopCheckCameraErrorInterval();
    }
  };

  const startCheckCameraErrorInterval = () => {
    if (!checkCameraErrorIntervalId.current) {
      checkCameraErrorIntervalId.current = setInterval(() => {
        console.log(cameraError, "cameraError");
        checkCameraError();
      }, 100);
    }
  };
  const stopCheckCameraErrorInterval = () => {
    if (checkCameraErrorIntervalId.current) {
      clearInterval(checkCameraErrorIntervalId.current);
      checkCameraErrorIntervalId.current = null;
    }
  };

  useEffect(() => {
    if (cameraError !== null && isCameraRequired && inviteId) {
      startCheckCameraErrorInterval();
    } else {
      checkCameraError();
    }
  }, [cameraError, isCameraRequired, inviteId]);

  useEffect(() => {
    if (isScreenSharingRequired && !isScreenShared) {
      setAlertMessage(
        "Don't stop screen sharing. It is required for the test."
      );
      setAction({
        action: "shareScreen",
        actionText: "Share Screen",
      });
      setShowModal(true);
    }
    //If screen sharing is required and screen is shared, take snapshot on add event listener which will be triggered on tab switch event and take snapshot
    if (isScreenSharingRequired && isScreenShared && inviteId) {
      const handleVisibilityChange = async () => {
        console.log(inviteId, "inviteId");
        if (document.hidden) {
          dispatch(takeScreenSnapshot(screenStream, testId, inviteId));
        }
      };
      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [isScreenSharingRequired, isScreenShared, inviteId]);

  let keys = [];
  useEffect(() => {
    settings?.map((setting) => {
      if (
        setting.setting === "Turn Off copy paste from external sources" &&
        setting.active
      ) {
        document.addEventListener("mousedown", (e) => {
          if (
            e.target.nodeName !== "INPUT" &&
            e.target.nodeName !== "TEXTAREA"
          ) {
            e.preventDefault();
            e.stopPropagation();
          }
        });
        // document.addEventListener("keydown", (e) => {
        //   keys.push(e.key.toLowerCase());
        //   if (keys.includes("t")) {
        //     document.body.style.filter = "blur(1000px)";
        //     keys = [];
        //   }
        //   console.log(keys);
        // });
        // document.addEventListener("keyup", (e) => {
        //   keys = keys.filter((key) => key !== e.key.toLowerCase());
        //   //remove the filter
        //   document.body.style.filter = "none";
        // });
        document.addEventListener("copy", (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
        document.addEventListener("cut", (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
        document.addEventListener("paste", (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
      }
    });
  }, [settings]);

  const handleAction = async () => {
    if (action.action === "enterFullScreen") {
      enterFullScreen();
      setShowModal(false);
    } else if (action.action === "shareScreen") {
      dispatch(askScreenShare());
      setShowModal(false);
    } else if (action.action === "startCamera") {
      dispatch(startCamera());
      if (!checkCameraErrorIntervalId.current) {
        setShowModal(false);
      }
    }
  };

  return (
    <Router>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={<route.component />} />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={testId ? <route.component /> : <Navigate to="/" />}
          />
        ))}
        {authProtectedRoutesFull.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={testId ? <route.component /> : <Navigate to="/" />}
          />
        ))}
        {!testId && <Route path="/*" element={<Navigate to="/" />} />}
      </Routes>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          exitFullScreen();
        }}
        className="alert-model"
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              if (action.action === "startCamera") {
                setShowModal(false);
              } else {
                handleAction();
              }
            }}
          ></button>
          <ModalBody>
            <div className="alert-trangle-text">
              <img src={AlertTriangle} alt="" />
              {alertMessage}
            </div>
          </ModalBody>
          <ModalFooter className="review-footer">
            {/* <Button
              color="secondary"
              onClick={() => {
                setShowModal(false);
                exitFullScreen();
              }}
              className="cancelModel"
            >
              Close
            </Button> */}
            <Button className="btn-warning-modal" onClick={handleAction}>
              {action?.actionText}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <div id="message" class="hidden">
        Only 10 minutes left!
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
